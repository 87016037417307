<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <!-- <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入礼物名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button> -->
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>
    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="gameId" label="游戏ID"></el-table-column>
      <el-table-column prop="gameName" label="游戏名称"></el-table-column>
      <el-table-column prop="showUser" label="可见用户">
        <template slot-scope="scope"> {{showUserOptions[scope.row.showUser]}} </template>
      </el-table-column>
      <el-table-column label="国家组">
        <template slot-scope="scope">
          <span v-for="item in scope.row.countryGroupIdEntity" :key="item">{{ countryGroupOptions[item]?.name }}、</span>
        </template>
      </el-table-column>
      <el-table-column label="白名单">
        <template slot-scope="scope">
          <p>{{scope.row.whiteListEntity.join(',')}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="showSwitch" label="是否显示开关">  
        <template slot-scope="scope">
          <span v-if="scope.row.showSwitch==1">显示</span>
          <span v-else>隐藏</span>
        </template>
      </el-table-column>
      <el-table-column prop="statusTxt" label="当前状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status" class="success">已上架</span>
          <span v-else class="warning">已下架</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="warning" 
            @click="unmountFunc(scope.row)"
            >{{scope.row.status?'下架':'上架'}}</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}礼物`" :visible.sync="addVisible" width="650px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="游戏ID" prop="gameId" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.gameId"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏名称" prop="gameName" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.gameName"
          ></el-input>
        </el-form-item>
        <el-form-item label="显示用户" prop="showUser" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.showUser">
            <el-radio :label="Number(key)" v-for="(item,key) in showUserOptions" :key="key">{{item}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="国家组">
          <el-checkbox-group v-model="ruleForm.countryGroupIdEntity">
            <el-checkbox :label="Number(key)" v-for="(item,key) in countryGroupOptions" :key="key">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="是否显示开关">
            <el-switch
              v-model="ruleForm.showSwitch"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#dcdfe6"
              active-text="开"
              inactive-text="关">
            </el-switch>
        </el-form-item>
        <el-form-item label="白名单">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="英文逗号分割"
            v-model="ruleForm.whiteListEntity">
          </el-input>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {status:0},
      searchForm: {
        name:undefined,
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      dialogVisible: false,
      countryGroupOptions: {},
      showUserOptions: {
        0: '所有用户',
        1: '钱包用户',
        2: 'nft用户',
      },
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
    this.getCountryGroupList();
  },
  methods: {
    async getCountryGroupList() {
      let res = await this.$http.countryGroupConfigList();
      if (res) {
        let countryGroup = res.data.filter(x=>x.id==500001)[0].configList
        this.countryGroupOptions = countryGroup.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
      }
    },
    addFunc(){
      this.ruleForm = {countryGroupIdEntity:[]};
      this.addVisible = true;
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.giftLogo=val[0].url
      }
    },
    uploadImgChange2(val){
      if(val&&val.length>0) {
        this.ruleForm.resourceUrl=val[0].url
      }
    },
    toEdit(data) {
      this.ruleForm = {...data}
      if(this.ruleForm.whiteListEntity) {
        this.ruleForm.whiteListEntity = this.ruleForm.whiteListEntity.join(',')
      }
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
          if(!ruleForm.id){
            ruleForm.status = 1
          }
          if(ruleForm.whiteListEntity) {
            ruleForm.whiteListEntity = ruleForm.whiteListEntity.split(',').map(x=>Number(x))
          } else {
            ruleForm.whiteListEntity = []
          }
          let res = await this.$http.gameSave({...ruleForm});
          if (res) {
            this.$message.success("修改成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.gameList({...this.searchForm});
      if (res) {
        this.tableData = res.result.records;
        this.totalNum = res.result.total;
      }
    },

    unmountFunc(row) {
      this.$confirm(`确定要${row.status?'下架':'上架'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.gameSave({ ...row , status:row.status==0?1:0 })
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.gameDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.icon{
  width: 60px;
}
</style>
